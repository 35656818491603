<template>
  <div>
    <b-card title="Secris 🚀">
      <b-card-text>
        Automated Penetration and Vulnerability scanning
      </b-card-text>
      <b-card-text>
        Please make sure to read our <b-link href="#" target="_blank">FAQ</b-link> to understand some of the
        concepts and terminology used in the Secris monitoring solution.
      </b-card-text>
    </b-card>

    <b-card title="Security Warnings 🔒">
      <b-card-text>Security issues will show up here.</b-card-text>
      <b-card-text>We haven't seen any unusual behaviour in the Secris portal.</b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  mounted() {},
}
</script>

<style>

</style>
